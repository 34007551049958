import React, { useState } from 'react';
import styled, { css } from 'styled-components';

import { ChevronIcon } from 'src/svg/chevron-icon';
import { OptionCheckmark } from 'src/svg/option-checkmark';

type OptionPickerProps = {
  label: string;
  selectedOption: string;
  onChange: (option: string) => void;
  options: Array<{
    label: string;
    value: string;
    description?: string;
    icon?: JSX.Element;
  }>;
};

export const OptionPicker = ({ label, selectedOption, onChange, options }: OptionPickerProps): JSX.Element => {
  const [isOpen, setIsOpen] = useState(false);
  const selectedOptionLabel = options.find((option) => option.value === selectedOption)?.label;

  const handleToggle = (): void => {
    setIsOpen((prev) => !prev);
  };

  return (
    <Container>
      <Summary onClick={handleToggle}>
        <Label>{label}</Label>

        <SummaryEndContent>
          {isOpen ? <Close>Close</Close> : <Selection>{selectedOptionLabel}</Selection>}
          <StyledChevron $isOpen={isOpen} />
        </SummaryEndContent>
      </Summary>

      {isOpen && (
        <Options>
          {options.map((option) => (
            <Option key={option.value} onClick={() => onChange(option.value)}>
              <OptionWrapper $hasDescription={!!option.description}>
                {option.icon && option.icon}
                <OptionLabel>{option.label}</OptionLabel>
                {option.value === selectedOption && <StyledCheckmark />}
              </OptionWrapper>
              {option.description && <OptionDescription>{option.description}</OptionDescription>}
            </Option>
          ))}
        </Options>
      )}
    </Container>
  );
};

const Container = styled.div`
  background: #fff;
  border-radius: 6px;
  border: 1px solid #d3d8de;
  box-shadow: 0 3px 5px #e9ecf1;
  width: 310px;
`;

const Summary = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 53px;
  justify-content: space-between;
  padding: 19px 20px;
`;

const Label = styled.div`
  color: #4f5d68;
  font-size: 13px;
  font-weight: bold;
`;

const SummaryEndContent = styled.div`
  align-items: center;
  display: flex;
  gap: 8px;
`;

const Close = styled.div`
  color: #0b99e6;
  cursor: pointer;
  font-size: 13px;
`;

const Selection = styled.div`
  color: #0b99e6;
  line-height: 1;
`;

const StyledChevron = styled(ChevronIcon)<{ $isOpen: boolean }>`
  fill: #969ea5;
  height: 8px;
  transform: ${({ $isOpen }) => $isOpen && 'rotate(180deg)'};
  width: 11px;
`;

const Options = styled.div`
  max-height: 325px;
  overflow-y: scroll;
`;

const Option = styled.div`
  cursor: pointer;
  padding: 12px 20px;
  width: 100%;

  :hover {
    background: #f6f6f6;
  }
`;

const OptionWrapper = styled.div<{ $hasDescription: boolean }>`
  align-items: center;
  display: flex;
  gap: 8px;

  ${({ $hasDescription }) =>
    $hasDescription &&
    css`
      color: #4f5d68;
      font-weight: bold;
    `}
`;

const OptionLabel = styled.div`
  flex: 1 1 auto;
  line-height: 1;
`;

const OptionDescription = styled.div`
  color: #5e696e;
  font-size: 13px;
  line-height: ${16 / 13};
  margin-top: 8px;
`;

const StyledCheckmark = styled(OptionCheckmark)`
  fill: #969ea5;
  height: 8px;
  width: 10px;
`;
