import React, { useState } from 'react';
import _ from 'lodash';
import { Box } from 'rebass/styled-components';
import styled from 'styled-components';
import { space, width } from 'styled-system';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import MaskedInput from 'react-number-format';
import Cleave from 'cleave.js/react';
import Autocomplete from 'react-google-autocomplete';

import { supportedCountries } from 'shared/core/constants/geography';
import { Tooltip } from 'shared/components';
import { mediaQueries } from 'shared/styles';

export const DefaultInput = ({ label, onChange, value, error, errorMessage, onValueAfterError = _.noop, ...props }) => (
  <StyledTextInputWithLabel
    variant='filled'
    error={!!error}
    label={error && errorMessage ? errorMessage : label}
    value={value}
    onChange={(e) => {
      if (onChange) {
        onChange(e);
      }
      if (error) {
        onValueAfterError(e);
      }
    }}
    {...props}
  />
);

export const FormattedInputWithButton = ({ buttonText, onSubmit, Input = DefaultInput, ...props }) => (
  <InputWithButtonContainer {...props}>
    <Input {...props} mt='0' mb='0' m='0' pt='0' pb='0' p='0' />{' '}
    {/* pass in one of the other Inputs exported from this file */}
    <InputButton onClick={onSubmit}>{buttonText || 'Save'}</InputButton>
  </InputWithButtonContainer>
);

const AddressAutoCompleteInput = ({ inputRef, onPlaceSelected, onBlur, id, ...other }) => (
  <Autocomplete
    types={['geocode']}
    componentRestrictions={{ country: supportedCountries }}
    onPlaceSelected={onPlaceSelected}
    onBlur={onBlur}
    id={id}
    ref={(r) => (r?.refs?.input ? inputRef(r.refs.input) : null)}
    inputAutocompleteValue='none'
    aria-autocomplete='list'
    role='combobox'
    {...other}
  />
);

export const AddressInput = ({ onPlaceSelected, onBlur, id, ...props }) => (
  <DefaultInput
    id={id}
    InputProps={{ inputComponent: AddressAutoCompleteInput }}
    inputProps={{ onPlaceSelected, onBlur, id }} /* eslint-disable-line */
    {...props}
  />
);

export const DateInput = (props) => (
  <MaskedInput
    displayType='input'
    customInput={DefaultInput}
    format='##/##/####'
    placeholder='mm/dd/yyyy'
    mask={['m', 'm', 'd', 'd', 'y', 'y', 'y', 'y']}
    {...props}
  />
);

export const ExpirationDateInput = (props) => (
  <MaskedInput
    displayType='input'
    customInput={DefaultInput}
    format='##/####'
    placeholder='mm/yyyy'
    mask={['m', 'm', 'y', 'y', 'y', 'y']}
    {...props}
  />
);

export const CvvInput = (props) => {
  const { width: ctnWidth } = props;
  return (
    <CvvContainer width={ctnWidth}>
      <MaskedInput displayType='input' customInput={DefaultInput} format='####' {...props} />
      <TooltipContainer>
        <Tooltip
          tooltipCopy='The 3 or 4 digit number commonly found on the front or back of your credit card.'
          grey
          width='257px'
          left='auto'
          right='-42px'
          bottom='25px'
          arrowRight='42px'
        />
      </TooltipContainer>
    </CvvContainer>
  );
};

export const PhoneNumberInput = (props) => (
  <MaskedInput
    displayType='input'
    customInput={DefaultInput}
    format='(###) ###-####'
    placeholder='(___) ___-____'
    mask='_'
    type='tel'
    {...props}
  />
);

const CreditCardCleaveInput = ({ onCreditCardTypeChanged = _.noop, _inputRef, ...props }) => (
  <Cleave
    {...props}
    options={{
      creditCard: true,
      onCreditCardTypeChanged,
    }}
  />
);

export const CreditCardInput = ({
  onCreditCardTypeChanged = _.noop,
  mt,
  mb,
  m,
  pt,
  pb,
  p,
  cardType: cardTypeFromProps,
  ...props
}) => {
  const [cardType, setCardType] = useState('');
  function cardTypeChanged(type) {
    setCardType(type);
    onCreditCardTypeChanged(type);
  }
  const InputProps = {
    inputComponent: CreditCardCleaveInput,
    inputProps: { onCreditCardTypeChanged: cardTypeChanged },
  };
  return (
    <CreditCardContainer className={`${cardTypeFromProps} ${cardType}`} mt={mt} mb={mb} m={m} pt={pt} pb={pb} p={p}>
      <DefaultInput InputProps={InputProps} {...props} />
    </CreditCardContainer>
  );
};

const DollarCleaveInput = ({ className, inputRef, value, ...props }) => (
  <Cleave
    {...props}
    value={value}
    className={`${className} --dollar-cleave ${value && String(value)?.length > 0 && '--has-value'}`}
    htmlRef={(ref) => inputRef(ref)}
    options={{
      prefix: '$',
      numeral: true,
      numeralPositiveOnly: true,
      numeralThousandsGroupStyle: 'thousand',
    }}
  />
);

export const DollarInput = ({ hasCurrencyLabel, currency = 'USD', ...props }) => {
  const InputProps = { inputComponent: DollarCleaveInput };
  if (hasCurrencyLabel) {
    InputProps.endAdornment = <EndAdornment position='end'>{currency}</EndAdornment>;
  }

  return <DefaultInput InputProps={InputProps} {...props} />;
};

const StyledTextInputWithLabel = styled(({ InputProps = {}, InputLabelProps = {}, containerClassName, ...props }) => (
  <TextField
    {...props}
    classes={{ root: 'mui-dt-root' }}
    InputProps={{
      ...InputProps,
      classes: {
        root: `mui-dt-input-root ${containerClassName || ''}`,
        input: 'mui-dt-input-input',
        error: 'mui-dt-input-error',
        underline: 'mui-dt-input-underline',
      },
    }}
    InputLabelProps={{
      ...InputLabelProps,
      classes: {
        root: 'mui-dt-label-root',
        focused: 'mui-dt-label-focused',
        error: 'mui-dt-label-error',
        shrink: 'mui-dt-label-shrink',
      },
    }}
  />
))`
  &.mui-dt-root {
    ${space};
    ${width};
  }

  .mui-dt-input-root {
    background-color: #fcfdfe !important;
    border: 1px solid #caced4;
    &.mui-dt-input-error {
      border: 1px solid #e25241;
    }

    &.iframe-input {
      height: 53px;
      @media only screen and (min-width: 768px) {
        height: 45px;
      }

      iframe {
        height: 16px !important;
        position: relative;
        transform: translate3d(19px, 5px, 0);
        transition: transform 0s, opacity 0.3s;
        @media only screen and (min-width: 768px) {
          transform: translate3d(17px, 5px, 0);
        }
      }
      .mui-dt-input-input {
        display: none;
      }
    }
    &.iframe-input.unfocused {
      iframe {
        /* HACK: Some versions of Chrome seem to disable iframe mouse events when set to opacity: 0 */
        opacity: 0.00000000001;
        transition: transform 0s 0.1s, opacity 0.1s;
        transform: translate3d(0, 0, 0);
        height: 100% !important;
      }
    }
  }

  .mui-dt-input-input {
    font-size: 16px;
    padding: 22px 5px 6px 20px;
    caret-color: #6fa48e;
    color: #5d666d;
    height: 23px;

    @media only screen and (min-width: 768px) {
      font-size: 13px;
      height: 19px;
      padding: 19px 5px 5px 18px;
    }

    &.--dollar-cleave:not(.--has-value) {
      color: transparent;

      &:focus {
        color: #5d666d;
      }
    }

    &.credit-card-cleave.visa {
      position: relative;
      &:before {
        content: ' ';
        height: 10px;
        width: 30px;
        background-color: red;
        position: absolute;
        top: 50%;
        right: 20px;
        transform: translate(-50%, -50%);
      }
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.75;
    }
  }

  .mui-dt-label-root {
    color: #858788;
    font-size: 16px;
    transform: translate(20px, 19px) scale(1);
    transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms, transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
      font-size 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    &.mui-dt-label-shrink {
      // transform: translate(20px, 10px) scale(0.8);
      transform: translate(20px, 10px);
      font-size: 10px;

      @media only screen and (min-width: 768px) {
        // transform: translate(18px, 8px) scale(0.8);
        transform: translate(18px, 8px);
        --webkit-transform: translate(18px, 8px);
        font-size: 10px;
      }
    }
    &.mui-dt-label-error {
      color: #e25241 !important;
    }
    &.mui-dt-label-focused {
      color: #858788;
    }

    @media only screen and (min-width: 768px) {
      font-size: 13px;
      transform: translate(18px, 17px) scale(1);
    }
  }
  .mui-dt-input-underline:before {
    display: none;
  }
  .mui-dt-input-underline:after {
    transform: scale(0) !important; // remove underline highlight
  }

  .MuiFormLabel-asterisk {
    display: none;
  }
  .focus-visible {
    box-shadow: none !important;
  }
  .Mui-focused {
    border-color: ${({ theme }) => theme?.colors?.blue[55] ?? '#4c94d7'} !important;
    & > input,
    textarea {
      box-shadow: 0px 1px 5px ${({ theme }) => theme?.colors?.blueShadow ?? 'rgba(11, 153, 230, 0.4)'} !important;
    }
    background-color: ${({ theme }) => theme?.colors?.white ?? '#fff'} !important;
  }
`;

const InputWithButtonContainer = styled(Box)`
  position: relative;

  div:first-of-type {
    width: 100%;
  }
`;

const InputButton = styled.button`
  appearance: none;
  border: none;
  padding: 0;
  background-color: transparent;
  position: absolute;
  top: 18px;
  right: 20px;
  font-size: 16px;
  font-weight: bold;
  color: #4597e0;
  cursor: pointer;
  @media only screen and (min-width: 768px) {
    top: 17px;
    font-size: 13px;
  }
`;

const EndAdornment = styled(InputAdornment)`
  p {
    color: #a3afba;
    font-size: 13px;
    font-weight: bold;
    line-height: 15px;
    padding: 2px 6px 0 0;
  }
`;

const CreditCardContainer = styled.div`
  position: relative;
  ${space};
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translate(-50%, -50%);
    background-repeat: no-repeat;
    height: 28px;
    width: 28px;
    background-image: url(/icons/default-card.svg);
    background-size: contain;
    @media ${mediaQueries.largePhone} {
      right: 2px;
    }
  }
  &.visa {
    &::after {
      background-image: url(/icons/visa.svg);
    }
  }
  &.mastercard,
  &.master.card {
    &::after {
      background-image: url(/icons/mastercard.svg);
    }
  }
  &.discover {
    &::after {
      background-image: url(/icons/discover.svg);
    }
  }
  &.amex,
  &.american.express {
    &::after {
      background-image: url(/icons/amex.svg);
    }
  }
`;

const CvvContainer = styled.div`
  position: relative;
  ${width};
`;

const TooltipContainer = styled.div`
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translate(0, -50%);
  z-index: 2; /* position above ui labels */
`;
