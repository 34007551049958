import { useForm, Control, UseFormMethods, SubmitHandler } from 'react-hook-form';

import { useParams } from 'react-router-dom';
import { TextTransformOption } from 'types/graphql';

import useErnie from 'shared/hooks/use-ernie';
import { PreviewData } from '../components/preview';
import { useUpdateWebCustomizationSettingsV2, MenuCustomizationData } from '../data-access';
import { FontsFormProps } from './fonts.types';

type HandlePublish = ReturnType<UseFormMethods<FormValues>['handleSubmit']>;

type FormValues = {
  heading: string;
  body: string;
  buttons: TextTransformOption;
  links: TextTransformOption;
  tags: TextTransformOption;
};

type Input = {
  fonts: {
    heading: string;
    body: string;
  };
  textTransforms: {
    buttons: TextTransformOption;
    links: TextTransformOption;
    tags: TextTransformOption;
  };
};

type UseFontsFormReturn = {
  control: Control<FormValues>;
  handlePublish: HandlePublish;
  handleReset: (this: void) => void;
  isDirty: boolean;
  previewData: PreviewData;
  applyAllInput: Input;
  afterApplyAll: () => void;
};

function useMergeDataWithFormValues(data: MenuCustomizationData, formValues: FormValues): PreviewData {
  const { heading, body, buttons, links, tags } = formValues;

  return {
    ...data,
    fonts: {
      heading,
      body,
    },
    textTransforms: {
      buttons,
      links,
      tags,
    },
  };
}

function formatInput(values: FormValues): Input {
  return {
    fonts: {
      heading: values.heading,
      body: values.body,
    },
    textTransforms: {
      buttons: values.buttons,
      links: values.links,
      tags: values.tags,
    },
  };
}

export function useFontsForm({ data }: FontsFormProps): UseFontsFormReturn {
  const {
    fonts: { heading, body },
    textTransforms: { buttons, links, tags },
  } = data;

  const defaultValues = {
    heading,
    body,
    buttons,
    links,
    tags,
  };

  const {
    control,
    formState: { isDirty },
    handleSubmit,
    reset,
    // eslint-disable-next-line @typescript-eslint/unbound-method
    watch,
  } = useForm<FormValues>({ defaultValues });

  const { id: dispensaryId } = useParams<{ id: string }>();

  const showErnie = useErnie();

  const updateData = useUpdateWebCustomizationSettingsV2();

  const onSubmit: SubmitHandler<FormValues> = async (submittedValues) => {
    const input = formatInput(submittedValues);

    try {
      await updateData({
        variables: {
          dispensaryId,
          input,
        },
      });

      showErnie('Your font settings have been updated', 'success');
      reset({ ...submittedValues });
    } catch (err) {
      showErnie('Something went wrong, please try again.', 'danger');
      console.error(err);
    }
  };

  const handlePublish = handleSubmit(onSubmit);

  const handleReset = (): void => reset();

  const formValues = watch();

  const previewData = useMergeDataWithFormValues(data, formValues);

  const applyAllInput = formatInput(formValues);
  const afterApplyAll = (): void => {
    reset({ ...formValues });
  };

  return {
    control,
    handlePublish,
    handleReset,
    isDirty,
    previewData,
    applyAllInput,
    afterApplyAll,
  };
}
