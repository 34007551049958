import React from 'react';
import styled from 'styled-components';
import { RadiusOption } from 'types/graphql';
import { radius } from 'shared/constants/customization';

type RadiusStyle = {
  sm: string;
  md: string;
  lg: string;
  xl: string;
  tags: string;
  cards: string;
  buttons: string;
};

const Rounded = styled.div`
  border: 2px solid #4f5d68;
  border-radius: 50%;
  height: 16px;
  width: 16px;
`;

const RoundedRectangle = styled.div`
  border: 2px solid #4f5d68;
  border-radius: 4px;
  height: 16px;
  width: 16px;
`;

const Square = styled.div`
  border: 2px solid #4f5d68;
  height: 16px;
  width: 16px;
`;

const adminRadiusOptions = {
  [RadiusOption.rounded]: {
    description: 'Buttons and other elements have fully rounded corners.',
    icon: <Rounded />,
  },
  [RadiusOption.roundedRectangle]: {
    description: 'Buttons and majority of elements have slightly rounded corners.',
    icon: <RoundedRectangle />,
  },
  [RadiusOption.square]: {
    description: 'Buttons and majority of elements have sharp corners.',
    icon: <Square />,
  },
};

export const radiusOptions = radius.map((option) => ({
  ...option,
  ...adminRadiusOptions[option.value],
}));

export const getRadiusStyle = (option: RadiusOption): RadiusStyle | null =>
  radius.find(({ value }) => value === option)?.style ?? null;
