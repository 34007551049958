import { useEffect } from 'react';
import { useObserver } from 'mobx-react-lite';

import { GqlUpdateWebCustomizationSettingsV2Input } from 'types/graphql';
import { ERNIE_TYPES } from 'shared/constants';
import useErnie from 'shared/hooks/use-ernie';
import { useUI } from 'src/hooks/use-ui';
import { useUser } from 'src/hooks/use-user';
import { useUpdateEnterpriseWebCustomizationSettingsV2 } from './data-access';

type UseApplyToAllLocationsProps = {
  input: Partial<GqlUpdateWebCustomizationSettingsV2Input>;
  afterApplyAll?: () => void;
  sectionName: string;
};

type UseApplyToAllLocationsReturn = {
  handleApplyToAllLocations: () => void;
  hasAccessToAllLocations: boolean;
};

export const useApplyToAllLocations = ({
  input,
  afterApplyAll,
  sectionName,
}: UseApplyToAllLocationsProps): UseApplyToAllLocationsReturn => {
  const showErnie = useErnie();
  const UI = useUI();
  const chainId = UI.dispensary.chain;
  const enterpriseId = UI.dispensary.retailer?.enterpriseId;
  const User = useUser();
  const dispoIdsWithPermission = User.profile.permissions?.dispensaryIds ?? [];
  const chainDispoIds = useObserver(() => UI.chainDispensaryIds);

  // This is a chain, make sure to fetch the chain ids
  useEffect(() => {
    if (chainId) {
      void UI.fetchChainDispensaryIds(chainId);
    }
  }, [UI, chainId]);

  const updateData = useUpdateEnterpriseWebCustomizationSettingsV2();

  const handleApplyToAllLocations = async (): Promise<void> => {
    if (!enterpriseId) {
      return;
    }

    await updateData({
      variables: {
        enterpriseId,
        input,
      },
    })
      .then(() => {
        showErnie(`${sectionName} has been updated for ${UI.chainDispensaryIds.length} locations`, ERNIE_TYPES.SUCCESS);
        afterApplyAll?.();
      })
      .catch((err) => {
        showErnie('Error publishing to all locations. Please try again', ERNIE_TYPES.DANGER);
        console.error(err);
      });
  };

  const hasAccessToAllLocations =
    (User.isSuperAdmin || dispoIdsWithPermission.length === chainDispoIds.length) && chainDispoIds.length > 0;

  return {
    handleApplyToAllLocations,
    hasAccessToAllLocations,
  };
};
