import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Flex, Image } from 'rebass/styled-components';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { datadogLogs } from '@datadog/browser-logs';
import { Controller, useForm } from 'react-hook-form';
import { useYupValidationResolver } from 'src/hooks/use-yup-resolver';
import { ModalContainer, ModalButton } from 'shared/modals';
import { openZendeskWidget } from 'shared/helpers/tools';
import BasicCheckbox from 'shared/components/formik/basic-checkbox';
import { Button } from 'shared/components';
import useErnie from 'shared/hooks/use-ernie';
import useUser from 'shared/hooks/use-user';
import { useErnieOnSubmitFormErrors } from 'src/utils/form';
import { setPasswordFromInviteSchema, SetPasswordFromInviteFormInput } from './schema';

function SetPasswordFromInviteLink(): JSX.Element | null {
  const User = useUser();
  const { logout, validatePasswordToken, setPassword } = User;
  const showErnie = useErnie();
  const history = useHistory();
  const tokenFromUrl = useRouteMatch<{ id: string }>('/set-password/:id');
  const token = tokenFromUrl?.params.id;
  const [showExpiredTokenView, setShowExpiredTokenView] = useState(false);

  const { handleFormErrors } = useErnieOnSubmitFormErrors();
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { control, watch, handleSubmit, formState } = useForm<SetPasswordFromInviteFormInput>({
    resolver: useYupValidationResolver(setPasswordFromInviteSchema),
    mode: 'onSubmit',
    defaultValues: {
      password: '',
      agree: false,
    },
  });
  const watchAgree = watch('agree');
  const { isSubmitting, isSubmitted, isSubmitSuccessful } = formState;

  // Log out any existing user
  useEffect(() => {
    logout()
      .then()
      .catch((err: any) => {
        showErnie('Something went wrong. Please contact support.', 'danger');
        datadogLogs.logger.error('Logout error', { pageName: 'setPasswordFromInviteLink', err });
      });
  }, [logout, showErnie]);

  // Validate token, show error view if invalid
  useEffect(() => {
    if (!token) {
      setShowExpiredTokenView(true);
    }
    validatePasswordToken(token)
      .then((isValid: boolean) => {
        setShowExpiredTokenView(!isValid);
      })
      .catch((err: any) => {
        setShowExpiredTokenView(true);
        showErnie('Invalid token. Please contact support.', 'danger');
        datadogLogs.logger.error('Validate password token error', { err });
      });
  }, [validatePasswordToken, showErnie, token]);

  function onClickSupport(event: React.MouseEvent<HTMLButtonElement>): void {
    event.preventDefault();
    openZendeskWidget();
  }

  async function savePassword(data: SetPasswordFromInviteFormInput): Promise<void> {
    if (isSubmitSuccessful) {
      return;
    }
    await setPassword(token, data.password)
      .then(() => {
        showErnie('Your password has been successfully set!', 'success');
        history.push('/login');
      })
      .catch((err: any) => {
        datadogLogs.logger.error('Set password error', { err });
        showErnie('There was an error setting your password. Please try again.', 'danger');
      });
  }

  return (
    <ModalContainer isOpen noPadding>
      {!showExpiredTokenView && (
        <InnerModalContainer>
          <HeaderBackground src='/images/password-modal-background.jpg' />
          <HeaderLogo src='/icons/dutchie-wordmark-white.svg' alt='Dutchie Logo' />
          <CtaText>
            To get started, <span>create a password</span> for your account.
          </CtaText>
          <form onSubmit={handleSubmit(savePassword, handleFormErrors)}>
            <Controller
              control={control}
              name='password'
              render={(props) => <Input disabled={isSubmitted && isSubmitSuccessful} type='password' {...props} />}
            />

            <LegalCheckbox>
              <Controller
                control={control}
                defaultValue={false}
                name='agree'
                render={({ onChange, name, value }) => (
                  <BasicCheckbox
                    checked={value}
                    name={name}
                    onChange={(e) => onChange(e.target.checked)}
                    value={value.toString()}
                    text=''
                  />
                )}
              />
              <LegalText>
                <label htmlFor='agree'>I agree to dutchie's </label>
                <a href='https://dutchie.com/dispensary-terms' target='_blank' rel='noreferrer'>
                  terms and conditions
                </a>
                .
              </LegalText>
            </LegalCheckbox>

            <ModalButton disabled={!watchAgree} loading={isSubmitting} type='submit' width='111px' mt='0px'>
              Continue
            </ModalButton>
          </form>
        </InnerModalContainer>
      )}
      {showExpiredTokenView && (
        <ExpiredLinkContainer p={16}>
          <SadFace src='/icons/404-face.svg' m='0 auto 34px auto' width='70px' />
          <ExpiredTitle>We're sorry, this link has expired!</ExpiredTitle>
          <ExpiredText>
            For security purposes, invite links expire after they've been used once. To resolve this issue, please have
            the user who originally sent your invite delete your user account and add you again. For any further
            questions{' '}
            <StyledSupportLink onClick={onClickSupport} type='button' link>
              contact dutchie support
            </StyledSupportLink>
            .
          </ExpiredText>
        </ExpiredLinkContainer>
      )}
    </ModalContainer>
  );
}
export default SetPasswordFromInviteLink;

const StyledSupportLink = styled(Button)`
  padding: 0;
  margin: 0;
  display: contents;
`;

const InnerModalContainer = styled(Flex)`
  width: 513px;
  height: 379px;
  padding-top: 109px;
  background-color: white;
  border-radius: 7px;
  box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.11);
  position: relative;
  overflow: hidden;
  flex-direction: column;
`;

const HeaderBackground = styled(Image)`
  position: absolute;
  width: 515px;
  max-width: 515px;
  top: -35px;
  left: -1px;
`;

const HeaderLogo = styled(Image)`
  width: 138px;
  position: absolute;
  top: 43px;
  left: 50%;
  transform: translateX(-50%);
  filter: drop-shadow(0 1px 2px rgba(0, 0, 0, 0.28));
`;

const CtaText = styled.p`
  width: 100%;
  text-align: center;
  margin-top: 45px;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  color: #59646e;
  span {
    font-weight: bold;
  }
`;

const Input = styled.input`
  appearance: none;
  padding: 0 30px;
  margin: 20px auto;
  font-size: 14px;
  color: #575e64;
  width: 295px;
  height: 45px;
  border-radius: 3px;
  background-color: #fcfdfe;
  border: solid 1px #d2d5da;

  &:focus {
    border: 1px solid #5bb0fc;
    outline: none;
    box-shadow: none;
  }
`;

const LegalCheckbox = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 26px;
`;

const LegalText = styled.span`
  font-size: 11px;
  line-height: 1.64;
  text-align: center;
  color: #788490;
  margin-left: -6px;
  label {
    cursor: pointer;
  }
  a {
    color: #4597e0;
  }
`;

const ExpiredLinkContainer = styled(InnerModalContainer)`
  justify-content: flex-start;
  padding: 46px 60px 0 60px;
  height: 420px;
  box-shadow: none;
`;

const SadFace = styled(Image)`
  width: 170px;
`;

const ExpiredTitle = styled.h2`
  text-align: center;
  color: #677682;
  font-size: 20px;
  font-weight: bolder;
  line-height: 1.5;
  margin-bottom: 26px;
`;

const ExpiredText = styled.p`
  text-align: center;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  color: #59646e;
  line-height: 1.8;
  a {
    cursor: pointer;
  }
`;
