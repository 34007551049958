import React from 'react';

import { formatCurrency } from 'shared/helpers/utils';

import { ProductCarouselData } from '../product-carousel.types';
import {
  Container,
  Price,
  Image,
  Name,
  Brand,
  Strain,
  Button,
  ButtonWrapper,
  Tags,
  DiscountTag,
  StaffPickTag,
} from './product-carousel-card.styles';
import { GetDefaultProductCarouselCardImage } from './product-carousel-card.utils';

export const TEST_ID_CAROUSEL_CARD = 'product-carousel-card';

type ProductCarouselCardProps = ProductCarouselData;

export function ProductCarouselCard({
  id,
  name,
  imageUrl,
  prices,
  brandName,
  strainType,
  type,
}: ProductCarouselCardProps): JSX.Element {
  const { imageSrc, onImageError } = GetDefaultProductCarouselCardImage({ id, imageUrl, type });

  return (
    <Container id={id} data-testid={TEST_ID_CAROUSEL_CARD}>
      <Image src={imageSrc} onError={onImageError} />

      <Price>{formatCurrency(prices[0])}</Price>

      <Tags>
        <DiscountTag>% off</DiscountTag>
        <StaffPickTag>Staff Pick</StaffPickTag>
      </Tags>

      <Name>{name}</Name>

      {brandName && <Brand>{brandName}</Brand>}

      <Strain>{strainType}</Strain>

      <ButtonWrapper>
        <Button>{prices.length > 1 ? 'Select weight' : 'Add to cart'}</Button>
      </ButtonWrapper>
    </Container>
  );
}
