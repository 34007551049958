import styled, { css } from 'styled-components';
import TinyColor from 'tinycolor2';

export const Container = styled.div`
  border-radius: ${({ theme }) => theme.radius.lg};
  box-shadow: none;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 15px;
  position: relative;
  transition: box-shadow 0.2s;
  width: 160px;

  @media (min-width: 600px) and (hover: hover) {
    &:hover,
    &:active {
      box-shadow: 0 5px 14px rgba(0, 0, 0, 0.1);
    }
  }
`;

export const Price = styled.p`
  color: ${({ theme }) => theme.linkColor};
  margin-bottom: 8px;
`;

export const Tags = styled.div`
  display: flex;
  gap: 5px;
  margin-bottom: 8px;
  width: 100%;
`;

const tagStyles = css`
  border-radius: ${({ theme }) => theme.radius.tags};
  color: #fff;
  font-size: 11px;
  font-weight: bold;
  line-height: 1;
  padding: 4px 10px;
  text-transform: ${({ theme }) => theme.textTransforms.tags};
`;

export const DiscountTag = styled.div`
  ${tagStyles};

  background: ${({ theme }) => theme.discountTagColor};
`;

export const StaffPickTag = styled.div`
  ${tagStyles};

  background: ${({ theme }) => theme.staffPickTagColor};
`;

export const Image = styled.img`
  width: 100%;
  max-width: 214px;
  margin-bottom: 12px;
`;

export const Name = styled.p`
  color: #242526;
  font-size: 14px;
  font-weight: bold;
  line-height: 17px;
  margin-bottom: 6px;
`;

export const Brand = styled.p`
  color: #242526;
  font-family: ${({ theme }) => theme.secondaryFont};
  font-size: 12px;
  font-weight: 400;
  line-height: 1.65;
`;

export const Strain = styled.p`
  color: #677882;
  font-family: ${({ theme }) => theme.secondaryFont};
  font-size: 11px;
  font-weight: bold;
  line-height: 1.2;
  text-transform: uppercase;
`;

export const ButtonWrapper = styled.div`
  align-items: flex-end;
  display: flex;
  flex: 1 0 auto;
  margin-top: 9px;
`;

export const Button = styled.button`
  appearance: none;
  /* Hex alpha 10% */
  background: ${({ theme }) => theme.linkColor}1A;
  border: none;
  border-radius: ${({ theme }) => theme.radius.buttons};
  color: ${({ theme }) => TinyColor.mix(theme.linkColor, '#000', 20).toString()};
  cursor: pointer;
  display: block;
  font-weight: bold;
  padding: 12px 5px;
  text-transform: ${({ theme }) => theme.textTransforms.buttons};
  width: 100%;

  &:hover {
    background: ${({ theme }) => theme.linkColor};
    color: ${({ theme }) => theme.colors.white};
  }
`;
