import React, { ReactElement, useState } from 'react';

import { Warning as WarningIcon } from 'src/svg/warning';
import { ErrorExclamation as ErrorIcon } from 'src/svg/error-exclamation';
import { Close } from 'src/svg/close';

import { CloseButton, Container, Content, Controls, IconContainer } from './inline-banner.styles';
import { VisuallyHidden } from '../visually-hidden';

type InlineBannerProps = {
  title?: string;
  description?: string;
  severity?: 'error' | 'info' | 'warning';
  cta?: ReactElement;
  padding?: string;
  fontSize?: string;
  hideIcon?: boolean;
  className?: string;
  onClose?: () => void;
};

export function InlineBanner({
  title,
  description,
  severity = 'warning',
  cta,
  padding = '16px',
  fontSize = '13px',
  hideIcon = false,
  className,
  onClose,
}: InlineBannerProps): JSX.Element | null {
  const [isOpen, setIsOpen] = useState(true);

  const handleCloseClick = (): void => {
    setIsOpen(false);
    onClose?.();
  };

  if (!isOpen) {
    return null;
  }

  return (
    <Container className={className} $severity={severity} $padding={padding} $fontSize={fontSize}>
      {!hideIcon && (
        <IconContainer>
          {severity === 'error' ? (
            <ErrorIcon />
          ) : (
            <WarningIcon fill={severity === 'info' ? 'currentColor' : undefined} />
          )}
        </IconContainer>
      )}

      <Content $hideIcon={hideIcon}>
        {title && <strong>{title}</strong>}
        {/* eslint-disable-next-line react/no-danger, @typescript-eslint/naming-convention */}
        {description && <div dangerouslySetInnerHTML={{ __html: description }} />}
      </Content>

      {(cta || onClose) && (
        <Controls>
          {cta}

          {onClose && (
            <CloseButton onClick={handleCloseClick} hasCta={!!cta}>
              <VisuallyHidden>Close</VisuallyHidden>
              <Close />
            </CloseButton>
          )}
        </Controls>
      )}
    </Container>
  );
}
