import React, { SVGAttributes } from 'react';

// rationale: SVG paths are looooong
/* eslint-disable max-len */

export function Close(props: SVGAttributes<unknown>): JSX.Element {
  return (
    <svg width='11' height='12' viewBox='0 0 11 12' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
      <path d='M10.7732 9.57719L7.19748 6.00089L10.7732 2.4246C11.2447 1.953 10.9068 1.53642 10.4353 1.06482C9.96375 0.593218 9.54724 0.278819 9.07572 0.726838L5.5 4.29527L1.92428 0.726838C1.45276 0.255239 1.03625 0.593218 0.564726 1.06482C0.0932031 1.53642 -0.221146 1.953 0.226801 2.4246L3.79466 6.00089L0.226801 9.57719C-0.244722 10.0488 0.0932031 10.4654 0.564726 10.937C1.03625 11.4086 1.45276 11.723 1.92428 11.2749L5.5 7.69865L9.07572 11.2749C9.54724 11.7465 9.96375 11.4007 10.4353 10.937C10.9068 10.4732 11.2447 10.0409 10.7732 9.57719Z' />
    </svg>
  );
}
