import React, { useState } from 'react';
import styled from 'styled-components';
import { HexColorPicker, HexColorInput } from 'react-colorful';
import useLocalStorage from 'react-use-localstorage';
import tinycolor from 'tinycolor2';

import { CheckmarkStroke } from 'src/svg/checkmark-stroke';
import { Button } from 'shared/components';
import { InlineBanner } from 'src/components/inline-banner';

type ColorPickerProps = {
  colors: string[];
  label: string;
  onSelect: (color: string) => void;
  selectedColor: string;
  allowCustomColorSelection?: boolean;
};

export const ACCESSIBLE_COLORS_BANNER = `<p>Selecting accessible colors is essential for readability and inclusivity, ensuring all users can interact with your website effectively.</p>

<p>Dutchie recommends aiming for <a href="https://www.w3.org/WAI/WCAG21/Understanding/contrast-minimum.html" target="_blank">WCAG Level AA</a> for text and UI components.</p>

<p><a href="https://webaim.org/resources/contrastchecker/" target="_blank">Click here</a> to test your color accessibility.</p>`;

const MAX_CUSTOM_COLORS = 24;

export function ColorPickerV2({
  colors,
  label,
  onSelect,
  selectedColor,
  allowCustomColorSelection = true,
}: ColorPickerProps): JSX.Element {
  const [isOpen, setIsOpen] = useState(false);
  const [isCustomColorPickerOpen, setIsCustomColorPickerOpen] = useState(false);
  const [customColor, setCustomColor] = useState('');
  const [isAccessibleColorsBannerOpen, setIsAccessibleColorsBannerOpen] = useState(false);
  const [customColors, setCustomColors] = useLocalStorage('customColors');
  const customColorOptions: Array<string> = JSON.parse(customColors || '[]');
  const isSelectedColorCustom = selectedColor && !colors.includes(selectedColor);
  const normalizedColor = customColor.toLowerCase();
  const isCustomColorInAvailableOptions = colors.includes(normalizedColor);
  const isCustomColorInCustomOptions = customColorOptions.includes(normalizedColor);
  const isCustomColorAlreadyPresent = isCustomColorInAvailableOptions || isCustomColorInCustomOptions;

  const toggleOpen = (): void => {
    setIsOpen((value) => !value);
  };

  const toggleCustomColorPicker = (): void => {
    setIsCustomColorPickerOpen((value) => !value);
  };

  const addCustomColor = (): void => {
    if (!customColor) {
      return;
    }

    customColorOptions.push(normalizedColor);

    if (customColorOptions.length > MAX_CUSTOM_COLORS) {
      customColorOptions.shift();
    }

    setCustomColors(JSON.stringify(customColorOptions));
    setIsCustomColorPickerOpen(false);
    onSelect(normalizedColor);
  };

  return (
    <Container>
      <Summary onClick={toggleOpen}>
        <Label>{label}</Label>

        {!isOpen && <Swatch $color={selectedColor} />}

        {isOpen && <HeaderButton>Close</HeaderButton>}
      </Summary>

      {isOpen && (
        <>
          <Options>
            {colors.map((color) => {
              const isSelected = color === selectedColor;

              return (
                <Option key={color}>
                  <Swatch $color={color} $isSelected={isSelected} onClick={() => onSelect(color)} title={color}>
                    {isSelected && <StyledCheckmarkStroke />}
                  </Swatch>
                </Option>
              );
            })}
          </Options>

          {allowCustomColorSelection && (
            <>
              <Header>
                <Label>Custom Colors</Label>
                <HeaderButton onClick={toggleCustomColorPicker}>+ Add</HeaderButton>
              </Header>

              {(isSelectedColorCustom || customColorOptions.length > 0) && (
                <Options>
                  {/* If a custom color is selected for dispo but it's not in local storage */}
                  {isSelectedColorCustom && !customColorOptions.includes(selectedColor) && (
                    <Option>
                      <Swatch $color={selectedColor} onClick={() => onSelect(selectedColor)} title={selectedColor}>
                        <StyledCheckmarkStroke />
                      </Swatch>
                    </Option>
                  )}

                  {/* Make a copy of the array and then reverse it */}
                  {customColorOptions
                    .slice()
                    .reverse()
                    .map((color) => {
                      const isSelected = color === selectedColor;

                      return (
                        <Option key={color}>
                          <Swatch $color={color} $isSelected={isSelected} onClick={() => onSelect(color)} title={color}>
                            {isSelected && <StyledCheckmarkStroke />}
                          </Swatch>
                        </Option>
                      );
                    })}
                </Options>
              )}

              {isCustomColorPickerOpen && (
                <CustomColorModal>
                  <HexColorPicker color={customColor} onChange={setCustomColor} />

                  <HexInputLabelWrapper>
                    <HexInputLabel>Hex Code</HexInputLabel>

                    <HexColorInput className='input' color={customColor} onChange={setCustomColor} prefixed />
                  </HexInputLabelWrapper>

                  {isCustomColorAlreadyPresent ? (
                    <>
                      <strong>{customColor}</strong>{' '}
                      {isCustomColorInCustomOptions
                        ? 'has already been added to custom colors'
                        : 'is already available as a color option'}
                    </>
                  ) : (
                    <Button onClick={addCustomColor} width='100%' disabled={!tinycolor(customColor).isValid()}>
                      Add color
                    </Button>
                  )}

                  <Button onClick={toggleCustomColorPicker} width='100%' link>
                    Cancel
                  </Button>

                  <Footnote>
                    <FootnoteButton onClick={() => setIsAccessibleColorsBannerOpen((value) => !value)}>
                      <img src='/icons/question-mark-grey.svg' alt='' height='14' width='14' />
                      Importance of accessible colors
                    </FootnoteButton>

                    {isAccessibleColorsBannerOpen && (
                      <StyledInlineBanner
                        severity='info'
                        padding='8px'
                        fontSize='11px'
                        hideIcon
                        description={ACCESSIBLE_COLORS_BANNER}
                      />
                    )}
                  </Footnote>
                </CustomColorModal>
              )}
            </>
          )}
        </>
      )}
    </Container>
  );
}

const Container = styled.div`
  background: #fff;
  border-radius: 6px;
  border: 1px solid #d3d8de;
  box-shadow: 0 3px 5px #e9ecf1;
  position: relative;
  width: 310px;
`;

const Header = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 19px 20px;
`;

const Summary = styled(Header)`
  cursor: pointer;
  height: 53px;
`;

const Label = styled.div`
  color: #4f5d68;
  font-size: 13px;
  font-weight: bold;
  line-height: 23px;
`;

const Swatch = styled.button<{ $color: string; $isSelected?: boolean }>`
  appearance: none;
  align-items: center;
  background: ${({ $color }) => $color};
  border: none;
  border-radius: 50%;
  box-shadow: ${({ $isSelected }) =>
    $isSelected ? `0 0 0 1px #00000099, inset 0 0 0 1px #00000099` : `inset 0 0 0 1px #00000033`};
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  height: 23px;
  justify-content: center;
  padding: 0;
  width: 23px;
`;

const StyledCheckmarkStroke = styled(CheckmarkStroke)`
  path {
    stroke: #00000099;
  }
`;

const HeaderButton = styled.button`
  appearance: none;
  background: none;
  border: none;
  color: #0b99e6;
  cursor: pointer;
  font-size: 13px;
  line-height: 23px;
  padding: 0;
`;

const Options = styled.ol`
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  padding: 0 20px 10px 20px;
  margin: -6px;
`;

const Option = styled.li`
  margin: 6px;
`;

const CustomColorModal = styled.div`
  background: #fff;
  border-radius: 6px;
  border: 1px solid #d3d8de;
  box-shadow: 0 3px 5px #e9ecf1;
  left: calc(100% + 8px);
  padding: 20px;
  position: absolute;
  top: 0;
  z-index: 3;
`;

const HexInputLabelWrapper = styled.label`
  align-items: center;
  display: flex;
  gap: 10px;
  margin: 12px 0 20px;

  input {
    border-radius: 6px;

    &:focus {
      border: 1px solid #7fb1f4;
      outline: none;
    }
  }
`;

const HexInputLabel = styled(Label)`
  cursor: pointer;
  line-height: 1;
  white-space: nowrap;
`;

const Footnote = styled.div`
  border-top: 1px solid #e4e8ec;
  padding: 12px 0 0;
`;

const FootnoteButton = styled.button`
  align-items: center;
  appearance: none;
  background: none;
  border: none;
  color: currentColor;
  cursor: pointer;
  display: flex;
  font-size: 11px;
  gap: 5px;
  justify-content: center;
  padding: 0;
  width: 100%;
`;

const StyledInlineBanner = styled(InlineBanner)`
  margin-top: 12px;
`;
