/* eslint-disable lodash/prefer-lodash-method */

// Menu Customization V2
export const navBarColors = [
  '#f3f6f8',
  '#ffffff',
  '#f3f3f3',
  '#f8f7f2',
  '#f3f8f2',
  '#f8f2f2',
  '#f3f2f8',
  '#485055',
  '#2c3236',
  '#141414',
];

export const buttonsLinksColors = [
  '#e80000',
  '#ff4343',
  '#d55454',
  '#d42121',
  '#c74222',
  '#f35000',
  '#f56b27',
  '#ec9017',
  '#a17e28',
  '#9a9418',
  '#6ac528',
  '#39ab03',
  '#46812a',
  '#39b159',
  '#00ac78',
  '#29b5a3',
  '#7b9d99',
  '#00899c',
  '#2f847f',
  '#0e5c7c',
  '#0862f7',
  '#004aaa',
  '#00b3da',
  '#0b99e6',
  '#001bc7',
  '#5710a3',
  '#7a68a0',
  '#7a5fb3',
  '#564df6',
  '#393f96',
  '#e3008c',
  '#b06896',
  '#c246ad',
  '#3e0c74',
  '#3b4559',
  '#e60b4d',
  '#ea005e',
  '#c30052',
  '#be000e',
  '#4d1635',
];

export const defaultTagColors = {
  staffPick: '#00ac78',
  discount: '#d55454',
};

// Menu Customization V1
export const navigationBarColorsV2 = {
  ALICE_BLUE: {
    background: '#F3F6F8',
    border: '#B8C7CF',
    color: '#454E50',
    key: 'alice-blue',
  },
  WHITE: {
    background: '#FFFFFF',
    border: '#CCCCCC',
    color: '#454E50',
    key: 'white',
  },
  WHITE_SMOKE: {
    background: '#F3F3F3',
    border: '#CFCFCF',
    color: '#454E50',
    key: 'white-smoke',
  },
  LIGHT_BROWN: {
    background: '#F8F7F2',
    border: '#D2D0BB',
    color: '#454E50',
    key: 'light-brown',
  },
};

export const linkColorsV2 = {
  RED_1: {
    background: '#E80000',
    border: '#AA0000',
    color: '#fff',
    key: 'red-1',
  },
  RED_2: {
    background: '#D42121',
    border: '#9B1D1D',
    color: '#fff',
    key: 'red-2',
  },
  RED_3: {
    background: '#C74222',
    border: '#8E1C00',
    color: '#fff',
    key: 'red-3',
  },
  ORANGE_1: {
    background: '#F35000',
    border: '#B83D01',
    color: '#fff',
    key: 'orange-1',
  },
  ORANGE_2: {
    background: '#F56B27',
    border: '#BC4E1B',
    color: '#fff',
    key: 'orange-2',
  },
  ORANGE_3: {
    background: '#EC9017',
    border: '#C16E00',
    color: '#fff',
    key: 'orange-3',
  },
  GREEN_1: {
    background: '#A17E28',
    border: '#80631C',
    color: '#fff',
    key: 'green-1',
  },
  GREEN_2: {
    background: '#6AC528',
    border: '#4D931A',
    color: '#fff',
    key: 'green-2',
  },
  GREEN_3: {
    background: '#39AB03',
    border: '#2D8B02',
    color: '#fff',
    key: 'green-3',
  },
  GREEN_4: {
    background: '#46812A',
    border: '#2D6214',
    color: '#fff',
    key: 'green-4',
  },
  AQUA_1: {
    background: '#00AC78',
    border: '#39856E',
    color: '#fff',
    key: 'aqua-1',
  },
  AQUA_2: {
    background: '#29B5A3',
    border: '#018B79',
    color: '#fff',
    key: 'aqua-2',
  },
  AQUA_3: {
    background: '#2F847F',
    border: '#175C58',
    color: '#fff',
    key: 'aqua-3',
  },
  BLUE_1: {
    background: '#00899C',
    border: '#105E69',
    color: '#fff',
    key: 'blue-1',
  },
  BLUE_2: {
    background: '#0E5C7C',
    border: '#1E3945',
    color: '#fff',
    key: 'blue-2',
  },
  BLUE_3: {
    background: '#00B3DA',
    border: '#018AA9',
    color: '#fff',
    key: 'blue-3',
  },
  BLUE_4: {
    background: '#0B99E6',
    border: '#0079BA',
    color: '#fff',
    key: 'blue-4',
  },
  BLUE_5: {
    background: '#004AAA',
    border: '#00306D',
    color: '#fff',
    key: 'blue-5',
  },
  PURPLE_1: {
    background: '#393F96',
    border: '#131964',
    color: '#fff',
    key: 'purple-1',
  },
  PURPLE_2: {
    background: '#5710A3',
    border: '#210342',
    color: '#fff',
    key: 'purple-2',
  },
  PURPLE_3: {
    background: '#7A5FB3',
    border: '#573B93',
    color: '#fff',
    key: 'purple-3',
  },
  PURPLE_4: {
    background: '#7A68A0',
    border: '#8C05A2',
    color: '#fff',
    key: 'purple-4',
  },
  PINK_1: {
    background: '#E60B4D',
    border: '#99012E',
    color: '#fff',
    key: 'pink-1',
  },
  PINK_2: {
    background: '#BE000E',
    border: '#79050E',
    color: '#fff',
    key: 'pink-2',
  },
  HARVEST_BLUE_1: {
    background: '#3b4559',
    border: '#222C40',
    color: '#fff',
    key: 'harvest-blue-1',
  },
};

// Accessing legacy options
export const navigationBarColors = Object.values(navigationBarColorsV2);
export const linkColors = Object.values(linkColorsV2);
