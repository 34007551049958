import gql from 'graphql-tag';

import baseDispensaryFragment from '../fragments/base-dispensary.gql';
import taxProperties from '../fragments/tax-properties-fragment.gql';
import weeklyHoursFragment from '../fragments/weekly-hours-fragment.gql';
import hoursSettingsForOrderTypeFragment from '../fragments/hours-settings-for-order-type-fragment.gql';
import inStorePickupOrderingSettingsFragment from '../fragments/in-store-pickup-ordering-settings-fragment.gql';
import curbsidePickupOrderingSettingsFragment from '../fragments/curbside-pickup-ordering-settings-fragment.gql';
import driveThruPickupOrderingSettingsFragment from '../fragments/drive-thru-pickup-ordering-settings-fragment.gql';
import deliveryOrderingSettingsFragment from '../fragments/delivery-ordering-settings-fragment.gql';
import deliveryZonesFragment from '../fragments/delivery-zones-fragment.gql';
import kioskOrderingSettingsFragment from '../fragments/kiosk-ordering-settings-fragment.gql';

export const chainDispensaryIds = gql`
  query ChainDispensaryIds($chainId: String, $status: String) {
    chainDispensaryIds(chainId: $chainId, status: $status)
  }
`;

export const chainDispensariesByStatus = gql`
  query ChainDispensariesByStatus($chainId: String, $status: String) {
    chainDispensariesByStatus(chainId: $chainId, status: $status) {
      id
      retailer {
        id
        assignedToBillingGroup
      }
    }
  }
`;

export const dispensaryAvailableReservations = gql`
  query DispensaryAvailableReservations($dispensaryFilter: dispensariesFilterInput!) {
    filteredDispensaries(filter: $dispensaryFilter) {
      reservationsAvailableByOrderType {
        inStorePickup {
          slotId
          startTimeISO
          endTimeISO
          quantityRemaining
        }
        curbsidePickup {
          slotId
          startTimeISO
          endTimeISO
          quantityRemaining
        }
        driveThruPickup {
          slotId
          startTimeISO
          endTimeISO
          quantityRemaining
        }
        delivery {
          slotId
          deliveryAreaId
          active
          startTimeISO
          endTimeISO
          quantityRemaining
        }
        pickup {
          slotId
          startTimeISO
          endTimeISO
          quantityRemaining
        }
      }
    }
  }
`;

export const consumerDispensaries = gql`
  ${baseDispensaryFragment}
  ${taxProperties}
  ${weeklyHoursFragment}
  ${hoursSettingsForOrderTypeFragment}
  ${inStorePickupOrderingSettingsFragment}
  ${curbsidePickupOrderingSettingsFragment}
  ${driveThruPickupOrderingSettingsFragment}
  ${deliveryOrderingSettingsFragment}
  ${kioskOrderingSettingsFragment}
  query ConsumerDispensaries($dispensaryFilter: dispensariesFilterInput!) {
    filteredDispensaries(filter: $dispensaryFilter) {
      ...baseDispensaryFragment
      hasMenuIntegration
      activeCategories
      consumerDispensaryIntegrations {
        alpineiq {
          enabled
          flags {
            combineDiscounts
          }
        }
        fyllo {
          enabled
          flags {
            combineDiscounts
          }
        }
        springbig {
          enabled
          flags {
            combineDiscounts
          }
        }
      }
      googleAnalyticsID
      googleTagManager {
        gtmID
        flags
      }
      hoursSettings {
        inStorePickup {
          ...hoursSettingsForOrderTypeFragment
          effectiveHours {
            ...weeklyHoursFragment
          }
        }
        curbsidePickup {
          ...hoursSettingsForOrderTypeFragment
          effectiveHours {
            ...weeklyHoursFragment
          }
        }
        driveThruPickup {
          ...hoursSettingsForOrderTypeFragment
          effectiveHours {
            ...weeklyHoursFragment
          }
        }
        delivery {
          ...hoursSettingsForOrderTypeFragment
          effectiveHours {
            ...weeklyHoursFragment
          }
        }
      }
      orderTypesConfigV2 {
        inStorePickup {
          ...inStorePickupOrderingSettingsFragment
        }
        curbsidePickup {
          ...curbsidePickupOrderingSettingsFragment
        }
        driveThruPickup {
          ...driveThruPickupOrderingSettingsFragment
        }
        delivery {
          ...deliveryOrderingSettingsFragment
        }
        kiosk {
          ...kioskOrderingSettingsFragment
        }
        offerAnyPickupService
        offerDeliveryService
      }
      retailer {
        enterpriseId
      }
      enabledOrderTypes {
        curbsidePickup
        delivery
        driveThruPickup
        inStorePickup
        kiosk
        pickup
      }
      merrcoPublicToken
      messagingSettings {
        disableReadyForPickup
        disableStartDelivery
      }
      springbigEnabled # deprecated in favor of consumerDispensaryIntegrations.springbig.enabled
      taxConfig {
        calculationMethod
        discountTaxOrder
        taxes {
          ...taxProperties
          destinationRate
        }
        version
      }
      webCustomizationSettings {
        colorSettings {
          navBarColor
          linkColor
        }
        fontSettings {
          family
        }
      }
      webCustomizationSettingsV2 {
        colors {
          buttonsLinks
          navBar
          staffPickTag
          discountTag
        }
        fonts {
          body
          heading
        }
        textTransforms {
          buttons
          links
          tags
        }
        radius {
          default
        }
      }
      imageBanners {
        _id
        image
        mobileImage
        link
        openInNewTab
        alt
        position
      }
      seoSettings {
        enabled
      }
      adSettings {
        enableAdsForDefault
        enableAdsForEmbedded
        enableAdsForKiosk
        enableAdsForStoreFront
      }
    }
  }
`;

export const adminDispensaries = gql`
  ${baseDispensaryFragment}
  ${taxProperties}
  ${hoursSettingsForOrderTypeFragment}
  ${inStorePickupOrderingSettingsFragment}
  ${curbsidePickupOrderingSettingsFragment}
  ${driveThruPickupOrderingSettingsFragment}
  ${deliveryOrderingSettingsFragment}
  ${deliveryZonesFragment}
  ${kioskOrderingSettingsFragment}
  query AdminDispensaries($dispensaryFilter: dispensariesFilterInput!) {
    filteredDispensaries(filter: $dispensaryFilter) {
      ...baseDispensaryFragment
      hoursSettings {
        inStorePickup {
          ...hoursSettingsForOrderTypeFragment
        }
        curbsidePickup {
          ...hoursSettingsForOrderTypeFragment
        }
        driveThruPickup {
          ...hoursSettingsForOrderTypeFragment
        }
        delivery {
          ...hoursSettingsForOrderTypeFragment
        }
      }
      orderTypesConfigV2 {
        inStorePickup {
          ...inStorePickupOrderingSettingsFragment
        }
        curbsidePickup {
          ...curbsidePickupOrderingSettingsFragment
        }
        driveThruPickup {
          ...driveThruPickupOrderingSettingsFragment
        }
        delivery {
          ...deliveryOrderingSettingsFragment
          ...deliveryZonesFragment
        }
        kiosk {
          ...kioskOrderingSettingsFragment
        }
        offerAnyPickupService
        offerDeliveryService
      }
      enabledOrderTypes {
        curbsidePickup
        delivery
        driveThruPickup
        inStorePickup
        kiosk
        pickup
      }
      tier
      timezone
      embeddedMenuUrl
      menuIntegration {
        live
        adapter
        lastSyncAt
        lastSyncAtISO
      }
      fleetManagementIntegration {
        live
        adapter
        flags {
          fleetManagementStatus
          onfleetStatus
        }
      }
      menuWeights
      isLibrary
      hideFromCCT
      firstActiveAt
      firstActiveAtISO
      embedSettings {
        iframeCSS
        pageCSS
        autoGTM
        autoScroll
        autoScrollOffset
        disclaimerTextHtml
        disableRouting
        disablePageLoadsAtTop
        applyToAllLocations
      }
      customDomainSettings {
        domain
        cloudflareDomain
      }
      plusSettings {
        checkoutUrl
        defaultReturnUrl
      }
      printedMenuSettings {
        Categories {
          category
          enabled
        }
        StrainTypes {
          strainType
          backgroundColor
          borderColor
        }
        Photos {
          enabled
        }
        HeaderText {
          text
        }
        FooterText {
          text
        }
        PageBreaks {
          enabled
        }
        MenuType {
          type
        }
      }
      retailer {
        id
        assignedToBillingGroup
        enterpriseId
        enterprise {
          id
          billingVersion
        }
      }
      taxConfig {
        version
        calculationMethod
        discountTaxOrder
        taxes {
          ...taxProperties
        }
      }
      messagingSettings {
        disableReadyForPickup
        disableStartDelivery
      }
      orderStatusEmailSettings {
        disableSubmitted
        disableConfirmed
        disableReadyForPickup
        disableOutForDelivery
      }
      storeSettings {
        rewardsIntegrationConfiguration {
          rewardsProgramDisplayDescription
          rewardsProgramDisplayEnrollment
          rewardsProgramDisplayName
        }
      }
      webCustomizationSettings {
        colorSettings {
          navBarColor
          linkColor
        }
        fontSettings {
          family
        }
      }
    }
  }
`;

export const chainDispensaryNamesAndIds = gql`
  query ChainDispensaryNamesAndIds($chainId: String) {
    chainDispensaryNamesAndIds(chainId: $chainId) {
      id
      name
      address
      retailer {
        id
        assignedToBillingGroup
      }
    }
  }
`;

export const getDeliveryInfo = gql`
  query GetDeliveryInfo($input: getDeliveryInfoInput!) {
    getDeliveryInfo(input: $input) {
      deliveryAreaId
      withinBounds
      fee
      minimum
      feeVaries
      minimumVaries
    }
  }
`;

export const activeDispensaries = gql`
  query ActiveDispensaries($params: activeDispensariesInput) {
    activeDispensaries(params: $params) {
      meta {
        allStates
      }
      dispensaries {
        _id
        integration {
          adapter
          lastSyncAt
        }
        profile {
          cName
          connectedProductPercentage
          embeddedPageViews
          hideFromCCT
          lastThirtyDaysSales
          menuScore
          name
          status
        }
      }
    }
  }
`;

export const getTimezone = gql`
  query GetTimezone($lat: Float, $lng: Float, $timeStamp: Int!) {
    getTimezone(lat: $lat, lng: $lng, timeStamp: $timeStamp) {
      timezone
    }
  }
`;
