import styled from 'styled-components';

export const Inputs = styled.div`
  flex: 0 0 auto;
  margin-bottom: 28px;
  min-width: 0;
`;

export const HelperText = styled.div`
  font-size: 13px;
  line-height: ${16 / 13};
  margin-top: 8px;
  width: 310px;
`;

export const Input = styled.div`
  margin-bottom: 20px;
`;

export const Heading = styled.h2`
  color: #454e50;
  font-size: 18px;
  font-weight: bold;
  line-height: 20px;
  margin-bottom: 11px;
`;

export const SubText = styled.p`
  color: #4f5d68;
  font-size: 13px;
  line-height: 21px;
  max-width: 510px;
  overflow-wrap: break-word;
  padding-bottom: 25px;
  width: 80%;
`;
