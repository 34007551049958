import { useReducer, useEffect, ChangeEvent } from 'react';
import uuid from 'uuid';
import { uploadImage } from 'shared/graphql/misc/mutations';
import { useStores } from 'src/hooks/use-stores';
import { readFile, imageFileSize } from './image-banner-modal.helper';
import { ImageBanner } from '../image-banner.types';

type UseImageBannerModalProps = {
  onClose: () => void;
  addBanner: (banner: ImageBanner) => void;
  bannerToEdit: ImageBanner | null;
};

type UseImageBannerModalReturn = {
  alt: string;
  link: string;
  openInNewTab: boolean;
  isUploadingImage: boolean;
  isUploadingMobileImage: boolean;
  image: string;
  mobileImage: string;
  handleSetAlt: (event: ChangeEvent<HTMLInputElement>) => void;
  handleSetLink: (event: ChangeEvent<HTMLInputElement>) => void;
  handleChangeLinkBehavior: (openInNewTab: boolean) => void;
  handleFile: (event: ChangeEvent<HTMLInputElement>) => void;
  handleSave: () => void;
  handleClose: () => void;
  isEditingBanner: boolean;
};

type BannerState = {
  alt: string;
  link: string;
  openInNewTab: boolean;
  isUploadingImage: boolean;
  isUploadingMobileImage: boolean;
  image: string;
  mobileImage: string;
};

export function useImageBannerModal({
  onClose,
  addBanner,
  bannerToEdit,
}: UseImageBannerModalProps): UseImageBannerModalReturn {
  const { apolloClient, UI } = useStores();

  const isEditingBanner = !!bannerToEdit && !!Object.keys(bannerToEdit).length;

  const [event, updateEvent] = useReducer(
    (state: BannerState, action: Partial<BannerState>): BannerState => ({ ...state, ...action }),
    {
      alt: '',
      link: '',
      openInNewTab: true,
      isUploadingImage: false,
      isUploadingMobileImage: false,
      image: '',
      mobileImage: '',
    }
  );

  useEffect(() => {
    if (isEditingBanner) {
      updateEvent({
        alt: bannerToEdit.alt ?? '',
        link: bannerToEdit.link ?? '',
        openInNewTab: bannerToEdit.openInNewTab ?? true,
        image: bannerToEdit.image,
        mobileImage: bannerToEdit.mobileImage,
      });
    } else {
      updateEvent({
        alt: '',
        link: '',
        openInNewTab: true,
        image: '',
        mobileImage: '',
      });
    }
  }, [
    bannerToEdit?.alt,
    bannerToEdit?.image,
    bannerToEdit?.link,
    bannerToEdit?.openInNewTab,
    bannerToEdit?.mobileImage,
    isEditingBanner,
  ]);

  function handleSetAlt(e: ChangeEvent<HTMLInputElement>): void {
    updateEvent({ alt: e.target.value });
  }

  function handleSetLink(e: ChangeEvent<HTMLInputElement>): void {
    updateEvent({ link: e.target.value });
  }

  function setImageUploading(inputName: string, value: boolean | string): void {
    if (inputName === 'mobile-image-banner' && typeof value === 'boolean') {
      updateEvent({ isUploadingMobileImage: value });
    } else if (inputName === 'mobile-image-banner' && typeof value === 'string') {
      updateEvent({ mobileImage: value });
    } else if (inputName === 'image-banner' && typeof value === 'boolean') {
      updateEvent({ isUploadingImage: value });
    } else if (inputName === 'image-banner' && typeof value === 'string') {
      updateEvent({ image: value });
    }
  }

  function handleFile(e: ChangeEvent<HTMLInputElement>): void {
    const bannerIamge = e.currentTarget.files;
    const inputName = e.currentTarget.name;
    const regex = /.(jpg|jpeg|png)$/i;
    if (!bannerIamge || !regex.test(bannerIamge[0].name)) {
      return;
    }

    setImageUploading(inputName, true);
    readFile(bannerIamge[0], async (readFileEvent) => {
      const approxFileSizeInMbs = imageFileSize(readFileEvent);
      if (approxFileSizeInMbs >= 20) {
        UI.showErnie(
          'The image you are uploading is too large. Please try again with a photo size less than 20MB.',
          'danger'
        );

        setImageUploading(inputName, false);
        return;
      }

      let newUrl;
      try {
        if (!readFileEvent.target) {
          return;
        }

        const response = await apolloClient.mutate({
          mutation: uploadImage,
          variables: { input: { image: readFileEvent.target.result } },
        });

        if (response.data.uploadImage.url) {
          newUrl = response.data.uploadImage.url;
        }
      } catch (error) {
        UI.showErnie('Error uploading photo. Please try again.', 'danger');
        console.error(error);
        setImageUploading(inputName, false);
        return;
      }

      setImageUploading(inputName, newUrl);
      setImageUploading(inputName, false);
    });
  }

  function handleChangeLinkBehavior(openInNewTab: boolean): void {
    updateEvent({ openInNewTab });
  }

  function handleSave(): void {
    const newImageBanner = {
      _id: isEditingBanner && bannerToEdit._id ? bannerToEdit._id : uuid.v4(),
      image: event.image,
      mobileImage: event.mobileImage,
      alt: event.alt,
      link: event.link,
      openInNewTab: event.openInNewTab,
      position: isEditingBanner && bannerToEdit.position ? bannerToEdit.position : 0,
    };

    addBanner(newImageBanner);
    handleClose();
  }

  function handleClose(): void {
    updateEvent({
      alt: '',
      link: '',
      isUploadingImage: false,
      isUploadingMobileImage: false,
      image: '',
      mobileImage: '',
    });

    onClose();
  }

  return {
    alt: event.alt,
    link: event.link,
    openInNewTab: event.openInNewTab,
    isUploadingImage: event.isUploadingImage,
    isUploadingMobileImage: event.isUploadingMobileImage,
    image: event.image,
    mobileImage: event.mobileImage,
    handleSetAlt,
    handleSetLink,
    handleChangeLinkBehavior,
    handleFile,
    handleSave,
    handleClose,
    isEditingBanner,
  };
}
