export const sansSerifFallbacks = [
  'Matter',
  'proxima-nova',
  '-apple-system',
  'BlinkMacSystemFont',
  '"Segoe UI"',
  '"Roboto"',
  '"Oxygen"',
  '"Ubuntu"',
  '"Cantarell"',
  '"Fira Sans"',
  '"Droid Sans"',
  '"Helvetica Neue"',
  '"Helvetica"',
  '"Arial"',
  'sans-serif',
];

export const serifFallbacks = ['Georgia', 'serif'];

export const fonts = {
  EXO: {
    fontFamily: 'Exo',
    fontFamilyEncoded: 'Exo',
    fallbacks: sansSerifFallbacks,
  },
  FIRA_SANS: {
    fontFamily: 'Fira Sans',
    fontFamilyEncoded: 'Fira+Sans',
    fallbacks: sansSerifFallbacks,
  },
  HEEBO: {
    fontFamily: 'Heebo',
    fontFamilyEncoded: 'Heebo',
    fallbacks: sansSerifFallbacks,
  },
  LATO: {
    fontFamily: 'Lato',
    fontFamilyEncoded: 'Lato',
    fallbacks: sansSerifFallbacks,
  },
  MATTER: {
    fontFamily: 'Matter',
    fontFamilyEncoded: 'Matter',
    localFont: true,
    fallbacks: sansSerifFallbacks,
  },
  MERRIWEATHER: {
    fontFamily: 'Merriweather',
    fontFamilyEncoded: 'Merriweather',
    fallbacks: serifFallbacks,
  },
  NOTO_SANS: {
    fontFamily: 'Noto Sans',
    fontFamilyEncoded: 'Noto+Sans',
    fallbacks: sansSerifFallbacks,
  },
  NUNITO: {
    fontFamily: 'Nunito',
    fontFamilyEncoded: 'Nunito',
    fallbacks: sansSerifFallbacks,
  },
  PROXIMA_NOVA: {
    fontFamily: 'proxima-nova',
    fontFamilyEncoded: 'proxima-nova',
    displayName: 'Proxima Nova',
    localFont: true,
    fallbacks: sansSerifFallbacks,
  },
  ROKKITT: {
    fontFamily: 'Rokkitt',
    fontFamilyEncoded: 'Rokkitt',
    fallbacks: serifFallbacks,
  },
  VOLKHOV: {
    fontFamily: 'Volkhov',
    fontFamilyEncoded: 'Volkhov',
    fallbacks: serifFallbacks,
  },
  VOLLKORN: {
    fontFamily: 'Vollkorn',
    fontFamilyEncoded: 'Vollkorn',
    fallbacks: serifFallbacks,
  },
  WORK_SANS: {
    fontFamily: 'Work Sans',
    fontFamilyEncoded: 'Work+Sans',
    fallbacks: sansSerifFallbacks,
  },
};

export const fontsV2 = {
  BITTER: {
    fontFamily: 'Bitter',
    fontFamilyEncoded: 'Bitter',
    fallbacks: serifFallbacks,
  },
  CRIMSON: {
    fontFamily: 'Crimson',
    fontFamilyEncoded: 'Crimson',
    fallbacks: serifFallbacks,
  },
  DM_SANS: {
    fontFamily: 'DM Sans',
    fontFamilyEncoded: 'DM+Sans',
    fallbacks: sansSerifFallbacks,
  },
  ENRIQUETA: {
    fontFamily: 'Enriqueta',
    fontFamilyEncoded: 'Enriqueta',
    fallbacks: serifFallbacks,
  },
  EXO: {
    fontFamily: 'Exo',
    fontFamilyEncoded: 'Exo',
    fallbacks: sansSerifFallbacks,
  },
  FIRA_SANS: {
    fontFamily: 'Fira Sans',
    fontFamilyEncoded: 'Fira+Sans',
    fallbacks: sansSerifFallbacks,
  },
  HEEBO: {
    fontFamily: 'Heebo',
    fontFamilyEncoded: 'Heebo',
    fallbacks: sansSerifFallbacks,
  },
  INTER: {
    fontFamily: 'Inter',
    fontFamilyEncoded: 'Inter',
    fallbacks: sansSerifFallbacks,
  },
  JOSEFIN_SANS: {
    fontFamily: 'Josefin Sans',
    fontFamilyEncoded: 'Josefin+Sans',
    fallbacks: sansSerifFallbacks,
  },
  LATO: {
    fontFamily: 'Lato',
    fontFamilyEncoded: 'Lato',
    fallbacks: sansSerifFallbacks,
  },
  LORA: {
    fontFamily: 'Lora',
    fontFamilyEncoded: 'Lora',
    fallbacks: serifFallbacks,
  },
  MATTER: {
    fontFamily: 'Matter',
    fontFamilyEncoded: 'Matter',
    localFont: true,
    fallbacks: sansSerifFallbacks,
  },
  MERRIWEATHER: {
    fontFamily: 'Merriweather',
    fontFamilyEncoded: 'Merriweather',
    fallbacks: serifFallbacks,
  },
  MONTSERRAT: {
    fontFamily: 'Montserrat',
    fontFamilyEncoded: 'Montserrat',
    fallbacks: sansSerifFallbacks,
  },
  NOTO_SANS: {
    fontFamily: 'Noto Sans',
    fontFamilyEncoded: 'Noto+Sans',
    fallbacks: sansSerifFallbacks,
  },
  NUNITO: {
    fontFamily: 'Nunito',
    fontFamilyEncoded: 'Nunito',
    fallbacks: sansSerifFallbacks,
  },
  OPEN_SANS: {
    fontFamily: 'Open Sans',
    fontFamilyEncoded: 'Open+Sans',
    fallbacks: sansSerifFallbacks,
  },
  PLAYFAIR: {
    fontFamily: 'Playfair',
    fontFamilyEncoded: 'Playfair',
    fallbacks: serifFallbacks,
  },
  POPPINS: {
    fontFamily: 'Poppins',
    fontFamilyEncoded: 'Poppins',
    fallbacks: sansSerifFallbacks,
  },
  PROXIMA_NOVA: {
    fontFamily: 'proxima-nova',
    fontFamilyEncoded: 'proxima-nova',
    displayName: 'Proxima Nova',
    localFont: true,
    fallbacks: sansSerifFallbacks,
  },
  PT_SANS: {
    fontFamily: 'PT Sans',
    fontFamilyEncoded: 'PT Sans',
    fallbacks: sansSerifFallbacks,
  },
  QUICKSAND: {
    fontFamily: 'Quicksand',
    fontFamilyEncoded: 'Quicksand',
    fallbacks: sansSerifFallbacks,
  },
  RALEWAY: {
    fontFamily: 'Raleway',
    fontFamilyEncoded: 'Raleway',
    fallbacks: sansSerifFallbacks,
  },
  ROBOTO: {
    fontFamily: 'Roboto',
    fontFamilyEncoded: 'Roboto',
    fallbacks: sansSerifFallbacks,
  },
  ROBOTO_SLAB: {
    fontFamily: 'Roboto Slab',
    fontFamilyEncoded: 'Roboto+Slab',
    fallbacks: serifFallbacks,
  },
  ROKKITT: {
    fontFamily: 'Rokkitt',
    fontFamilyEncoded: 'Rokkitt',
    fallbacks: serifFallbacks,
  },
  RUBIK: {
    fontFamily: 'Rubik',
    fontFamilyEncoded: 'Rubik',
    fallbacks: sansSerifFallbacks,
  },
  SPACE_GROTESK: {
    fontFamily: 'Space Grotesk',
    fontFamilyEncoded: 'Space Grotesk',
    fallbacks: sansSerifFallbacks,
  },
  TITILLIUM_WEB: {
    fontFamily: 'Titillium Web',
    fontFamilyEncoded: 'Titillium+Web',
    fallbacks: sansSerifFallbacks,
  },
  UBUNTU: {
    fontFamily: 'Ubuntu',
    fontFamilyEncoded: 'Ubuntu',
    fallbacks: sansSerifFallbacks,
  },
  VOLKHOV: {
    fontFamily: 'Volkhov',
    fontFamilyEncoded: 'Volkhov',
    fallbacks: serifFallbacks,
  },
  VOLLKORN: {
    fontFamily: 'Vollkorn',
    fontFamilyEncoded: 'Vollkorn',
    fallbacks: serifFallbacks,
  },
  WORK_SANS: {
    fontFamily: 'Work Sans',
    fontFamilyEncoded: 'Work+Sans',
    fallbacks: sansSerifFallbacks,
  },
};
