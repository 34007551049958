import React, { useState } from 'react';
import styled from 'styled-components';

import { useUI } from 'src/hooks/use-ui';
import RouteLeavingGuard from 'src/routes/route-leaving-guard';
import PublishModal from 'src/dispensary-admin/publish-modal';
import RichTextHtmlWrapper from 'shared/components/rich-text-html-wrapper';
import WarningModal from 'src/modals/warning-modal';
import { ApplyToAll as ApplyToAllIcon } from 'src/svg/apply-to-all';
import { ContentContainer, Detail, Page, Title } from './customize.styles';
import { PublishBanner } from './publish-banner';
import { TextBannerNotificationModal } from './modals/text-banner-notification';

type MenuCustomizationLayoutProps = {
  headerText: string;
  subHeaderText: string;
  isDirty: boolean;
  handlePublish: () => void;
  handleReset: () => void;
  children: React.ReactNode | React.ReactNode[];
  previewComponent?: JSX.Element;
  handleApplyToAllLocations?: () => void;
  hasAccessToAllLocations?: boolean;
};

export function MenuCustomizationLayout({
  headerText,
  subHeaderText,
  isDirty,
  handlePublish,
  handleReset,
  previewComponent,
  children,
  handleApplyToAllLocations,
  hasAccessToAllLocations = false,
}: MenuCustomizationLayoutProps): JSX.Element {
  const [isWarningModalOpen, setIsWarningModalOpen] = useState(false);
  const UI = useUI();
  const shouldShowApplyToAll = handleApplyToAllLocations && hasAccessToAllLocations && !!UI.dispensary.chain;
  const chainDispoCount = UI.chainDispensaryIds.length;

  const closePublishAllLocationsModal = (): void => {
    setIsWarningModalOpen(false);
  };

  const openPublishAllLocationsModal = (): void => {
    setIsWarningModalOpen(true);
  };

  const publishAllLocations = (): void => {
    handleApplyToAllLocations?.();
    setIsWarningModalOpen(false);
  };

  return (
    <>
      <Page>
        <TextBannerNotificationModal />
        <Container>
          <LeftColumn>
            <Title>{headerText}</Title>
            <Detail>
              <RichTextHtmlWrapper html={subHeaderText} className='menu-customization-layout' />
            </Detail>
            <ContentContainer>{children}</ContentContainer>
          </LeftColumn>

          {(previewComponent || (shouldShowApplyToAll && !isDirty)) && (
            <RightColumn>
              {shouldShowApplyToAll && !isDirty && (
                <ApplyToAllContainer>
                  <ApplyToAllButton onClick={openPublishAllLocationsModal}>
                    <ApplyToAllIcon />
                    Apply to all locations
                  </ApplyToAllButton>
                </ApplyToAllContainer>
              )}

              {previewComponent}
            </RightColumn>
          )}
        </Container>
      </Page>

      {isDirty && (
        <PublishBanner
          onPublish={handlePublish}
          shouldShowPublishToAll={shouldShowApplyToAll}
          onPublishToAll={openPublishAllLocationsModal}
          headerText={headerText}
        />
      )}

      <RouteLeavingGuard when={isDirty} modal={PublishModal} handleConfirm={handlePublish} handleDeny={handleReset} />

      {shouldShowApplyToAll && (
        <WarningModal
          isOpen={isWarningModalOpen}
          onClose={closePublishAllLocationsModal}
          onConfirm={publishAllLocations}
          text='Publish to all locations?'
          body={`By confirming, you will update the ${headerText} selections for ${String(
            chainDispoCount
          )} locations. This action cannot be undone.<br />Are you sure you want to publish these changes?`}
          modalWidth='495px'
          buttonGap='16px'
          buttonContainerWidth='auto'
          yesText='Yes, publish changes'
          noText='No, do not publish'
        />
      )}
    </>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

const LeftColumn = styled.div`
  width: calc(100% - 400px);
`;

const RightColumn = styled.div`
  margin-left: 30px;
  width: 370px;
`;

const ApplyToAllContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const ApplyToAllButton = styled.button`
  align-items: center;
  appearance: none;
  background: none;
  border: none;
  color: ${({ theme }) => theme.colors.primaryBlue};
  cursor: pointer;
  display: flex;
  gap: 8px;
  margin-bottom: 35px;
  padding: 0;
`;
