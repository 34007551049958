import { useForm, Control, UseFormMethods, SubmitHandler } from 'react-hook-form';

import { useParams } from 'react-router-dom';
import { RadiusOption } from 'types/graphql';

import useErnie from 'shared/hooks/use-ernie';
import { PreviewData } from '../components/preview';
import { useUpdateWebCustomizationSettingsV2, MenuCustomizationData } from '../data-access';
import { ThemeFormProps } from './theme.types';

type HandlePublish = ReturnType<UseFormMethods<FormValues>['handleSubmit']>;

type FormValues = {
  buttonsLinks: string;
  navBar: string;
  staffPickTag: string;
  discountTag: string;
  defaultRadius: RadiusOption;
};

type Input = {
  colors: {
    buttonsLinks: string;
    navBar: string;
    staffPickTag: string;
    discountTag: string;
  };
  radius: {
    default: RadiusOption;
  };
};

type UseThemeFormReturn = {
  control: Control<FormValues>;
  handlePublish: HandlePublish;
  handleReset: () => void;
  isDirty: boolean;
  previewData: PreviewData;
  applyAllInput: Input;
  afterApplyAll: () => void;
};

function useMergeDataWithFormValues(this: void, data: MenuCustomizationData, formValues: FormValues): PreviewData {
  const { buttonsLinks, navBar, staffPickTag, discountTag, defaultRadius } = formValues;

  return {
    ...data,
    colors: {
      buttonsLinks,
      navBar,
      staffPickTag,
      discountTag,
    },
    radius: {
      default: defaultRadius,
    },
  };
}

function formatInput(values: FormValues): Input {
  return {
    colors: {
      buttonsLinks: values.buttonsLinks,
      navBar: values.navBar,
      staffPickTag: values.staffPickTag,
      discountTag: values.discountTag,
    },
    radius: {
      default: values.defaultRadius,
    },
  };
}

export function useThemeForm({ data }: ThemeFormProps): UseThemeFormReturn {
  const {
    colors: { buttonsLinks, navBar, staffPickTag, discountTag },
    radius: { default: defaultRadius },
  } = data;

  const defaultValues = {
    buttonsLinks,
    navBar,
    defaultRadius,
    // Need to account for flag being off
    staffPickTag: staffPickTag ?? '',
    discountTag: discountTag ?? '',
  };

  const {
    control,
    formState: { isDirty },
    handleSubmit,
    reset,
    // eslint-disable-next-line @typescript-eslint/unbound-method
    watch,
  } = useForm<FormValues>({ defaultValues });

  const { id: dispensaryId } = useParams<{ id: string }>();

  const showErnie = useErnie();

  const updateData = useUpdateWebCustomizationSettingsV2();

  const onSubmit: SubmitHandler<FormValues> = async (submittedValues) => {
    const input = formatInput(submittedValues);

    try {
      await updateData({
        variables: {
          dispensaryId,
          input,
        },
      });

      showErnie('Your theme has been updated', 'success');
      reset({ ...submittedValues });
    } catch (err) {
      showErnie('Something went wrong, please try again.', 'danger');
      console.error(err);
    }
  };

  const handlePublish = handleSubmit(onSubmit);

  const handleReset = (): void => reset();

  const formValues = watch();

  const previewData = useMergeDataWithFormValues(data, formValues);

  const applyAllInput = formatInput(formValues);
  const afterApplyAll = (): void => {
    reset({ ...formValues });
  };

  return {
    control,
    handlePublish,
    handleReset,
    isDirty,
    previewData,
    applyAllInput,
    afterApplyAll,
  };
}
