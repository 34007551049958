import React from 'react';
import { Controller } from 'react-hook-form';
import styled from 'styled-components';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { navBarColors, buttonsLinksColors } from 'shared/constants/menu-colors';
import { InlineBanner } from 'src/components/inline-banner';
import { radiusOptions } from '../helpers';
import { MenuCustomizationLayout } from '../components/menu-customization-layout';
import { PreviewSwitcher } from '../components/preview-switcher';
import { OptionPicker } from '../components/option-picker';
import { ColorPickerV2, ACCESSIBLE_COLORS_BANNER } from '../components/color-picker-v2';

import { useApplyToAllLocations } from '../use-apply-to-all-locations';
import { useThemeForm } from './use-theme-form';

import { ThemeFormProps } from './theme.types';
import { Heading, Input, Inputs, SubText } from '../customize.styles';

export function ThemeForm(props: ThemeFormProps): JSX.Element {
  const flags = useFlags();
  const isCustomizationTagsEnabled = flags['growth.ecomm.menu-customization-v2-tags.rollout'] ?? false;

  const sectionName = 'Theme';
  const { control, handlePublish, handleReset, isDirty, previewData, applyAllInput, afterApplyAll } = useThemeForm(
    props
  );
  const { handleApplyToAllLocations, hasAccessToAllLocations } = useApplyToAllLocations({
    input: applyAllInput,
    afterApplyAll,
    sectionName,
  });

  return (
    <MenuCustomizationLayout
      headerText={sectionName}
      subHeaderText='Select how main elements such as buttons, cards and fields will appear.'
      isDirty={isDirty}
      handlePublish={handlePublish}
      handleReset={handleReset}
      previewComponent={<PreviewSwitcher previewData={previewData} />}
      handleApplyToAllLocations={handleApplyToAllLocations}
      hasAccessToAllLocations={hasAccessToAllLocations}
    >
      <Inputs>
        <Input>
          <Controller
            name='defaultRadius'
            control={control}
            render={({ onChange, value }) => (
              <OptionPicker label='Style' options={radiusOptions} onChange={onChange} selectedOption={value} />
            )}
          />
        </Input>
      </Inputs>

      <Heading>Colors</Heading>
      <SubText>Select colors to express your brand.</SubText>

      <Inputs>
        <Input>
          <Controller
            name='navBar'
            control={control}
            render={({ onChange, value }) => (
              <ColorPickerV2
                label='Navigation Bar'
                colors={navBarColors}
                onSelect={onChange}
                selectedColor={value}
                allowCustomColorSelection={false}
              />
            )}
          />
        </Input>

        <Input>
          <Controller
            name='buttonsLinks'
            control={control}
            render={({ onChange, value }) => (
              <ColorPickerV2
                label='Buttons and Links'
                colors={buttonsLinksColors}
                onSelect={onChange}
                selectedColor={value}
              />
            )}
          />
        </Input>

        {isCustomizationTagsEnabled && (
          <>
            <Input>
              <Controller
                name='staffPickTag'
                control={control}
                render={({ onChange, value }) => (
                  <ColorPickerV2
                    label='Staff Pick Tag'
                    colors={buttonsLinksColors}
                    onSelect={onChange}
                    selectedColor={value}
                  />
                )}
              />
            </Input>

            <Input>
              <Controller
                name='discountTag'
                control={control}
                render={({ onChange, value }) => (
                  <ColorPickerV2
                    label='Discount Tag'
                    colors={buttonsLinksColors}
                    onSelect={onChange}
                    selectedColor={value}
                  />
                )}
              />
            </Input>
          </>
        )}
      </Inputs>

      <StyledInlineBanner severity='info' title='Important!' description={ACCESSIBLE_COLORS_BANNER} />
    </MenuCustomizationLayout>
  );
}

const StyledInlineBanner = styled(InlineBanner)`
  width: 310px;
`;
