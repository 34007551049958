import { useFlags } from 'launchdarkly-react-client-sdk';

import { fonts as menuFonts } from 'shared/constants/menu-fonts';
import { defaultTagColors } from 'shared/constants/menu-colors';
import { navigationBarColors, linkColors, isColorDark, getTextTransformStyle, getRadiusStyle } from '../../helpers';
import { PreviewTheme, PreviewData } from './preview.types';
import { nonLocalFonts } from '../../fonts/fonts.constants';

type UsePreviewReturn = {
  theme: PreviewTheme;
  encodedFonts: string;
};

export function usePreview(data: PreviewData): UsePreviewReturn {
  const { colorSettings, fontSettings, colors, fonts, textTransforms, radius } = data;
  const { navBar, buttonsLinks, staffPickTag, discountTag } = colors;
  const { heading, body } = fonts;
  const { buttons, links, tags } = textTransforms;
  const flags = useFlags();
  const isCustomizationV2Enabled = flags['growth.ecomm.menu-customization-v2.rollout'] ?? false;

  const navBarColor = navigationBarColors[colorSettings.navBarColor];
  const linkColor = linkColors[colorSettings.linkColor];
  const font = menuFonts[fontSettings.family];
  const bodyFont = isCustomizationV2Enabled ? body : font.fontFamily;
  const headingFont = isCustomizationV2Enabled ? heading : font.fontFamily;

  const theme = {
    isNavColorDark: isCustomizationV2Enabled ? isColorDark(navBar) : false,
    navColor: isCustomizationV2Enabled ? navBar : navBarColor.background,
    linkColor: isCustomizationV2Enabled ? buttonsLinks : linkColor.background,
    staffPickTagColor: staffPickTag ?? defaultTagColors.staffPick,
    discountTagColor: discountTag ?? defaultTagColors.discount,
    fontFamily: font.fontFamily,
    secondaryFont: `'${bodyFont}'`,
    primaryFont: `'${headingFont}'`,
    textTransforms: {
      buttons: getTextTransformStyle(buttons),
      links: getTextTransformStyle(links),
      tags: getTextTransformStyle(tags),
    },
    radius: getRadiusStyle(radius.default),
  };

  // Return only google fonts correctly encoded to include in stylesheet request
  const selectedFonts = new Set(
    nonLocalFonts.filter((fontOption) => [bodyFont, headingFont].includes(fontOption.fontFamily))
  );
  const encodedFonts = Array.from(selectedFonts)
    .map((fontOption) => `family=${fontOption.fontFamilyEncoded}`)
    .join('&');

  return {
    theme,
    encodedFonts,
  };
}
