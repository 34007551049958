import React from 'react';
import { Controller } from 'react-hook-form';

import { textTransforms } from 'shared/constants/customization';

import { MenuCustomizationLayout } from '../components/menu-customization-layout';
import { PreviewSwitcher } from '../components/preview-switcher';
import { FontSelectorV2 } from '../components/fonts-selector-v2';
import { OptionPicker } from '../components/option-picker';

import { useApplyToAllLocations } from '../use-apply-to-all-locations';
import { useFontsForm } from './use-fonts-form';
import { FontsFormProps } from './fonts.types';
import { nonLocalFonts } from './fonts.constants';

import { Heading, HelperText, Input, Inputs, SubText } from '../customize.styles';

export function FontsForm(props: FontsFormProps): JSX.Element {
  const sectionName = 'Fonts';
  const { control, handlePublish, handleReset, isDirty, previewData, applyAllInput, afterApplyAll } = useFontsForm(
    props
  );
  const { handleApplyToAllLocations, hasAccessToAllLocations } = useApplyToAllLocations({
    input: applyAllInput,
    afterApplyAll,
    sectionName,
  });

  return (
    <MenuCustomizationLayout
      headerText={sectionName}
      subHeaderText='Select fonts to express your brand.'
      isDirty={isDirty}
      handlePublish={handlePublish}
      handleReset={handleReset}
      previewComponent={<PreviewSwitcher previewData={previewData} />}
      handleApplyToAllLocations={handleApplyToAllLocations}
      hasAccessToAllLocations={hasAccessToAllLocations}
    >
      {nonLocalFonts.map((font) => (
        <link
          href={`https://fonts.googleapis.com/css2?family=${font.fontFamilyEncoded}&text=${encodeURIComponent(
            font.fontFamily
          )}`}
          rel='stylesheet'
          key={font.fontFamily}
        />
      ))}

      <Inputs>
        <Input>
          <Controller
            name='heading'
            control={control}
            render={({ onChange, value }) => (
              <FontSelectorV2 label='Heading Font' selectedOption={value} onChange={onChange} />
            )}
          />

          <HelperText>Larger text Including titles, button text, links and navigation text.</HelperText>
        </Input>

        <Input>
          <Controller
            name='body'
            control={control}
            render={({ onChange, value }) => (
              <FontSelectorV2 label='Body Font' selectedOption={value} onChange={onChange} />
            )}
          />

          <HelperText>Smaller text including product details, descriptions, captions, tag labels and more.</HelperText>
        </Input>
      </Inputs>

      <Heading>Text Transform</Heading>
      <SubText>Select how text will display on buttons, tags and links</SubText>

      <Inputs>
        <Input>
          <Controller
            name='buttons'
            control={control}
            render={({ onChange, value }) => (
              <OptionPicker label='Buttons' options={textTransforms} selectedOption={value} onChange={onChange} />
            )}
          />
        </Input>

        <Input>
          <Controller
            name='links'
            control={control}
            render={({ onChange, value }) => (
              <OptionPicker label='Links' options={textTransforms} selectedOption={value} onChange={onChange} />
            )}
          />
        </Input>

        <Input>
          <Controller
            name='tags'
            control={control}
            render={({ onChange, value }) => (
              <OptionPicker label='Tags' options={textTransforms} selectedOption={value} onChange={onChange} />
            )}
          />
        </Input>
      </Inputs>
    </MenuCustomizationLayout>
  );
}
