import { HTMLAttributes } from 'react';
import styled, { css } from 'styled-components';

const SEVERITY_COLORS = {
  error: {
    background: '#FFF0F0',
    border: '#D8C1C1',
    text: '#8E5656',
  },
  info: {
    background: '#F3F6F8',
    border: '#D8DEE3',
    text: '#454E50',
  },
  warning: {
    background: '#FFFDEB',
    border: '#D8D5BA',
    text: '#75725B',
  },
};

export const Container = styled.div<{ $severity: 'error' | 'info' | 'warning'; $padding: string; $fontSize: string }>`
  background: ${({ $severity }) => SEVERITY_COLORS[$severity].background};
  border: 1px solid ${({ $severity }) => SEVERITY_COLORS[$severity].border};
  border-radius: 4px;
  color: ${({ $severity }) => SEVERITY_COLORS[$severity].text};
  display: flex;
  font-size: ${({ $fontSize }) => $fontSize};
  line-height: ${20 / 13};
  padding: ${({ $padding }) => $padding};
`;

export const IconContainer = styled.div`
  flex-shrink: 0;
  flex-grow: 0;
  margin-right: 16px;
`;

export const Content = styled.div<{ $hideIcon: boolean }>`
  ${({ $hideIcon }) =>
    !$hideIcon &&
    css`
      flex: 1 0 auto;
      max-width: calc(100% - 36px);
    `}

  strong {
    font-weight: bold;
  }

  p + p {
    margin-top: 14px;
  }
`;

export const Controls = styled.div`
  align-items: center;
  display: flex;

  a,
  button {
    background: none;
    border: none;
    color: currentColor;
    cursor: pointer;
    font: inherit;
    font-weight: bold;
    padding: 0;

    &:hover {
      color: currentColor;
    }
  }

  svg {
    display: block;
  }

  path {
    fill: currentColor;
  }
`;

export const CloseButton = styled.button<
  HTMLAttributes<HTMLButtonElement> & {
    hasCta: boolean;
  }
>`
  position: relative;

  /* Make click area a bit bigger */
  &::before {
    bottom: -12px;
    content: '';
    left: -12px;
    position: absolute;
    right: -12px;
    top: -12px;
  }

  /* If we also have a CTA add the vertical separator */
  ${({ hasCta }) =>
    hasCta &&
    css`
      margin-left: 32px;

      &::after {
        border-left: 1px solid currentColor;
        content: '';
        height: 16px;
        left: -16px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
      }
    `}
`;
